<script setup>
import AppLogo from "@images/app.png";
import AppSquareLogo from "@images/app-square.png";
import { computed } from "vue";

const props = defineProps({
    type: {
        type: String,
        default: "primary", // primary, secondary, danger, success, warning, info, light, dark, gray
    },
});

const colorClass = {
    primary: "bg-primary-100 text-primary-800",
    secondary: "bg-secondary-100 text-secondary-800",
    success: "bg-success-100 text-success-800",
    danger: "bg-danger-100 text-danger-800",
    warning: "bg-warning-100 text-warning-800",
    info: "bg-info-100 text-info-800",
    light: "bg-light-100 text-light-800",
    dark: "bg-dark-100 text-white",
    gray: "bg-gray-100 text-gray-800",
};
</script>

<template>
    <div
        class="p-4 mb-4 text-sm rounded-lg"
        :class="colorClass[props.type]"
        role="alert"
    >
        <slot></slot>
    </div>
</template>
